import { Loading } from 'components'
import { useApi, useBarcodeScanner, useBoolean } from 'hooks'
import { useCallback } from 'react'
import { Button, CardBody } from 'reactstrap'
import { askQuestion } from 'utils'
import { debugLog } from './../utils'
import type { StepProps } from '..'
import ShipOptions from './ShipOptions'
import useLabelPrinter from './useLabelPrinter'

const Step = ({ state, dispatch, printQueue, reloadOrderData }: StepProps) => {
  const printer = useLabelPrinter({ printQueue })

  const {
    orderData,
    boxes,
    numBoxes,
    didVoid,
    isAdvancedShipping,
    carrierCode,
    carrierId,
    serviceCode,
    packageCode,
    weight,
    allowMultipleLabels,
    showLabelActionButtons,
  } = state

  const isConfirming = useBoolean(false)

  const apiCreateShipment = useApi(() => ({
    action: 'shipments_createShipment',
  }))

  const confirmPrintLabel = useCallback(async () => {
    const { label, orderSplitId, orderNumber } = orderData

    if (label) {
      return
    }

    if (apiCreateShipment.isLoading) {
      return
    }

    debugLog(`Creating shipment for orderSplit ${orderSplitId}`)

    let json: Record<string, any> = { orderSplitID: orderSplitId }

    if (isAdvancedShipping) {
      json = {
        ...json,
        isAdvancedShipping,
        carrierId,
        serviceCode,
        packageCode,
        weight,
      }
    } else {
      json = { ...json, carrierCode, boxes, allowMultipleLabels }
    }

    const ret = await apiCreateShipment.performRequest({ json })

    if (ret) {
      reloadOrderData()

      const { shipment, label } = ret

      debugLog(
        `Shipment created shipment_id=${shipment.id} label_id=${label.id}`,
      )

      dispatch({ type: 'SET_LABEL', label })

      await printer.printLabel(orderNumber, label.id)
      await printer.printSlip(orderNumber, orderSplitId, numBoxes)
    }
  }, [
    dispatch,
    reloadOrderData,
    apiCreateShipment,
    boxes,
    numBoxes,
    orderData,
    printer,
    isAdvancedShipping,
    carrierId,
    carrierCode,
    serviceCode,
    packageCode,
    weight,
    allowMultipleLabels,
  ])

  const apiVoidLabel = useApi(() => ({
    action: 'shipments_voidLabel',
  }))

  const canVoid = orderData?.label ? orderData.numValidLabels === 1 : false

  const voidLabel = useCallback(async () => {
    if (!canVoid) {
      return
    }

    if (orderData?.label?.id) {
      if (
        !(await askQuestion(
          'Are you sure you want to void the label for this order?',
        ))
      ) {
        return
      }

      const ret = await apiVoidLabel.performRequest({
        json: { id: orderData.label.id },
      })

      reloadOrderData()

      if (ret) {
        dispatch({ type: 'DID_VOID' })
      }
    }
  }, [dispatch, apiVoidLabel, orderData, canVoid, reloadOrderData])

  useBarcodeScanner(
    useCallback(
      (barcode) => {
        if (
          barcode.indexOf('TTCCLEAR') === 0 ||
          barcode.indexOf('TTCNO') === 0
        ) {
          dispatch({ type: 'RESET_BOX_COUNT' })
          isConfirming.off()
        } else if (barcode.indexOf('BOX') === 0) {
          if (!isConfirming.value) {
            dispatch({ type: 'INC_BOX_COUNT', sku: barcode })
          }
        } else if (barcode === 'TTCSHIPOPTIONS') {
          dispatch({ type: 'SET_ADVANCED_SHIPPING' })
        } else if (barcode === 'TTCNEWLABEL') {
          dispatch({ type: 'CONFIRM_ADDONS' })
          dispatch({ type: 'CONFIRM_NUM_PLANTS' })
          dispatch({ type: 'SET_LABEL', label: null })
          dispatch({ type: 'ALLOW_MULTIPLE_LABELS' })
        } else if (barcode === 'TTCCONFIRM') {
          if (isConfirming.value) {
            confirmPrintLabel()
          } else {
            isConfirming.on()
          }
        } else if (barcode === 'TTCLABELACTIONS') {
          dispatch({ type: 'TOGGLE_LABEL_ACTIONS' })
        }
      },
      [dispatch, confirmPrintLabel, isConfirming],
    ),
  )

  const handleClickComplete = useCallback(() => {
    confirmPrintLabel()
  }, [confirmPrintLabel])

  const handleClickPrintLabel = useCallback(() => {
    if (orderData.label != null) {
      printer.printLabel(state.orderData.orderNumber, orderData.label.id)
    }
  }, [printer, orderData, state.orderData.orderNumber])

  const handleClickPrintSlip = useCallback(() => {
    printer.printSlip(
      state.orderData.orderNumber,
      state.orderData.orderSplitId,
      1,
    )
  }, [printer, state.orderData])

  const handlePrintAgain = useCallback(() => {
    handleClickPrintLabel()
    handleClickPrintSlip()
  }, [handleClickPrintLabel, handleClickPrintSlip])

  const hasLabel = Boolean(state.orderData.label)

  return (
    <CardBody className="text-center" style={{ fontSize: 24 }}>
      {didVoid ? (
        <div className="p-3">❌ Label has been voided. Create new label:</div>
      ) : null}

      {!hasLabel || isAdvancedShipping ? (
        <ShipOptions
          {...{
            isConfirming,
            apiCreateShipment,
            dispatch,
            state,
            handleClickComplete,
          }}
        />
      ) : (
        <>
          <div className="p-3">
            <div style={{ lineHeight: '90px', fontSize: 90 }}>✅</div>
          </div>
          <div className="p-3">
            Success! Order {orderData.orderNumber} is shipped!
            <br />
          </div>
          <div className="p-3">
            Your customer will receive a tracking number in 10 minutes.
          </div>
        </>
      )}

      {showLabelActionButtons && hasLabel && !isAdvancedShipping ? (
        <>
          <Button
            size="lg"
            color="success"
            className="m-3"
            onClick={handlePrintAgain}
          >
            Print again
          </Button>
          <Button
            size="lg"
            color="success"
            className="m-3"
            onClick={handleClickPrintLabel}
          >
            Print labels only
          </Button>
          <Button
            size="lg"
            color="success"
            className="m-3"
            onClick={handleClickPrintSlip}
          >
            Print slip only
          </Button>
          <Button
            disabled={!canVoid || apiVoidLabel.isLoading}
            size="lg"
            color="danger"
            className="m-3"
            onClick={voidLabel}
          >
            VOID LABEL
          </Button>
        </>
      ) : null}

      {apiCreateShipment.error != null ? (
        <div className="alert alert-danger" role="alert">
          {apiCreateShipment.error}
        </div>
      ) : null}
      {apiCreateShipment.isLoading ? <Loading /> : null}

      {apiVoidLabel.error != null ? (
        <div className="alert alert-danger" role="alert">
          {apiVoidLabel.error}
        </div>
      ) : null}
      {apiVoidLabel.isLoading ? <Loading /> : null}

      {printer.error != null ? (
        <div className="alert alert-danger" role="alert">
          {printer.error}
        </div>
      ) : null}
      {printer.isLoading ? <Loading /> : null}
    </CardBody>
  )
}

export default Step
