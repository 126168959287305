import { LoadingImage } from 'components'
import { useDropDownToggler, useStateful } from 'hooks'
import { useApi } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modal = withReactContent(Swal)

type LineItem = {
  id: string
  name: string
  sku: string
  product_id: string
  quantity: number
}

const ItemsEditor = (props: {
  lineItems: LineItem[]
  onChangeRules: (rules: any) => void
}) => {
  const { onChangeRules, lineItems } = props
  const qtys = useStateful({})

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.currentTarget.getAttribute('data-line-item')
      qtys.update({ [id]: e.currentTarget.value })
    },
    [qtys],
  )

  const isValid = useMemo(() => {
    let origTotal = 0
    let total = 0

    for (const lineItem of lineItems) {
      origTotal += Number(lineItem.quantity)

      if (Object.hasOwn(qtys.value, lineItem.id)) {
        const qty = qtys.value[lineItem.id]

        if (!/^[0-9]+$/.test(String(qty))) {
          return false
        }

        total += Number(qty)

        if (qty > lineItem.quantity) {
          return false
        }
      }
    }

    return total > 0 && total < origTotal
  }, [lineItems, qtys])

  useEffect(() => {
    onChangeRules(qtys.value)
  }, [onChangeRules, qtys])

  useEffect(() => {
    Modal.getConfirmButton().disabled = !isValid
  }, [isValid])

  return (
    <>
      <div className="text-left" style={{ fontSize: 14 }}>
        <table className="table" style={{ width: '100%' }}>
          <colgroup>
            <col />
            <col />
            <col width="160px" />
            <col width="160px" />
          </colgroup>
          <thead>
            <tr>
              <td>SKU</td>
              <td>Product</td>
              <td>Quantity</td>
              <td>Split Qty</td>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((lineItem) => {
              const { id, name, sku, product_id, quantity } = lineItem

              return (
                <tr key={id}>
                  <td className="align-middle">{sku}</td>
                  <td className="align-middle">{name}</td>
                  <td className="align-middle">{quantity}</td>
                  <td className="align-middle">
                    <input
                      onChange={handleChangeInput}
                      className="form-control"
                      type="number"
                      name="qty"
                      data-product={product_id}
                      data-line-item={id}
                      value={Object.hasOwn(qtys.value, id) ? qtys.value[id] : 0}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

type SplitActionButtonProps = {
  selectedRows: string[]
  onOrderModified: () => void
}

const SplitActionButton = (props: SplitActionButtonProps) => {
  const { selectedRows, onOrderModified } = props

  const toggler = useDropDownToggler()

  const apiAction = useApi(null, null, { errorModal: true })

  const handleClickSplitOrder = useCallback(async () => {
    const id = selectedRows[0]

    const order = await apiAction.performRequest({
      action: 'editOrder_getOrder',
      order_id: id,
    })
    if (!order) {
      return
    }

    let newOrder = null
    let rules = {}

    const handleChangeRules = (newRules: any) => {
      rules = newRules
    }

    const lineItems = order.line_items.filter((lineItem) => lineItem.canSplit)

    await Modal.fire({
      width: '800px',
      title: `Split Order ${order.orderNumber}`,
      html: (
        <ItemsEditor lineItems={lineItems} onChangeRules={handleChangeRules} />
      ),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Split',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        Swal.showLoading()

        try {
          newOrder = await apiAction.performRequest({
            action: 'orders_split',
            json: { id, rules },
          })

          return true
        } catch (e) {
          Swal.showValidationMessage(e.message)
          return false
        }
      },
    })

    if (onOrderModified) {
      onOrderModified()
    }

    if (newOrder) {
      Swal.fire(`New order ${newOrder.orderNumber} has been created.`)
    }
  }, [selectedRows, apiAction, onOrderModified])

  const isLoading = apiAction.isLoading
  const disabled = isLoading || selectedRows.length !== 1

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={disabled ? 'disabled' : ''}>
        Split {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickSplitOrder}>
          Split Order...
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SplitActionButton
