import { useApi } from 'hooks'
import type { ApiCall } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import type { Action, State } from '..'
import type { ApiCarrier } from '@ttc/api/shipments'
import carrierIcons from '../carrierIcons'
import { assetPath } from 'utils/index'

type BoxInputProps = {
  dispatch: React.Dispatch<Action>
  state: State
  sku: string
  label?: string
  disabled: boolean
}

const BoxInput = ({ dispatch, state, sku, label, disabled }: BoxInputProps) => {
  const handleChangeInput = useCallback(
    (e) => {
      const count = e.target.value

      if (count >= 0) {
        dispatch({ type: 'SET_BOX_COUNT', sku, count })
      }
    },
    [dispatch, sku],
  )

  const value = String(state.boxes[sku.toUpperCase()] || '')

  return (
    <div className="m-3">
      <div className="mb-2">{label}</div>
      <Input
        style={{ fontSize: 24, width: 100 }}
        className="text-center p-0"
        name="orderNumber"
        type="number"
        value={value === '0' ? '' : value}
        onInput={handleChangeInput}
        autoComplete="off"
        disabled={disabled}
      />
    </div>
  )
}

type AdvancedShipOptionsProps = {
  dispatch: React.Dispatch<Action>
  state: State
  handleClickComplete: () => void
}

const AdvancedShipOptions = ({
  dispatch,
  state,
  handleClickComplete,
}: AdvancedShipOptionsProps) => {
  const { carriers } = state

  const apiGetCarriers = useApi(
    () => ({ action: 'shipments_getCarriers' }),
    null,
    { autoPerform: true },
  )

  useEffect(() => {
    if (
      !state.carriers.length &&
      apiGetCarriers.result &&
      Object.hasOwn(apiGetCarriers.result, 'carriers')
    ) {
      dispatch({
        type: 'SET_CARRIERS',
        carriers: apiGetCarriers.result.carriers,
      })
    }
  }, [dispatch, apiGetCarriers.result, state.carriers])

  const carrier = useMemo(
    () =>
      (carriers as ApiCarrier[]).find((c) => c.carrier_id === state.carrierId),
    [carriers, state.carrierId],
  )

  const services = useMemo(() => (carrier ? carrier.services : []), [carrier])

  const packages = useMemo(() => (carrier ? carrier.packages : []), [carrier])

  const handleChangeCarrier = useCallback(
    (e) => {
      dispatch({ type: 'SET_CARRIER', carrierId: e.target.value })
    },
    [dispatch],
  )

  const handleChangeServiceCode = useCallback(
    (e) => {
      dispatch({ type: 'SET_SERVICE_CODE', serviceCode: e.target.value })
    },
    [dispatch],
  )

  const handleChangePackageCode = useCallback(
    (e) => {
      dispatch({ type: 'SET_PACKAGE_CODE', packageCode: e.target.value })
    },
    [dispatch],
  )

  const handleChangeWeight = useCallback(
    (e) => {
      dispatch({ type: 'SET_WEIGHT', weight: e.target.value })
    },
    [dispatch],
  )

  const canSubmit =
    apiGetCarriers.hasResult &&
    state.carrierId != null &&
    state.serviceCode != null &&
    state.packageCode != null

  return (
    <>
      <div className="p-3">Advanced Shipping Options</div>

      {carriers.length ? (
        <Form style={{ width: 600, margin: 'auto', padding: 20 }}>
          <FormGroup row>
            <Label sm={4} for="input_select_carrier">
              Carrier
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                name="carrier"
                id="input_select_carrier"
                onChange={handleChangeCarrier}
                value={state.carrierId}
              >
                {carriers.map((c) => {
                  return (
                    <option key={c.carrier_id} value={c.carrier_id}>
                      {c.friendly_name}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </FormGroup>
          {carrier ? (
            <FormGroup row>
              <Label sm={4} for="input_select_service">
                Service
              </Label>
              <Col sm={8}>
                <Input
                  type="select"
                  name="service"
                  id="input_select_service"
                  onChange={handleChangeServiceCode}
                  value={state.serviceCode}
                >
                  {services.map((c) => {
                    return (
                      <option key={c.service_code} value={c.service_code}>
                        {c.name}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </FormGroup>
          ) : null}
          {state.serviceCode ? (
            <>
              <FormGroup row>
                <Label sm={4} for="input_select_package">
                  Package
                </Label>
                <Col sm={8}>
                  <Input
                    type="select"
                    name="package"
                    id="input_select_package"
                    onChange={handleChangePackageCode}
                    value={state.packageCode}
                  >
                    {packages.map((c) => {
                      return (
                        <option key={c.package_code} value={c.package_code}>
                          {c.name}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} for="input_select_weight">
                  Weight (oz)
                </Label>
                <Col sm={8}>
                  <Input
                    type="text"
                    name="weight"
                    id="input_select_weight"
                    onChange={handleChangeWeight}
                    value={state.weight}
                  />
                </Col>
              </FormGroup>
            </>
          ) : null}
        </Form>
      ) : null}

      <Button
        size="lg"
        color="danger"
        className="m-3"
        disabled={!canSubmit}
        onClick={handleClickComplete}
      >
        Complete &amp; Print
      </Button>
    </>
  )
}

type ShipOptionsProps = {
  apiCreateShipment: ApiCall
  state: State
  isConfirming: any
  dispatch: React.Dispatch<Action>
  handleClickComplete: () => void
}

export const ShipOptions = (props: ShipOptionsProps) => {
  const {
    isConfirming,
    apiCreateShipment,
    state,
    dispatch,
    handleClickComplete,
  } = props
  const { numBoxes, isAdvancedShipping } = state

  const handleClickNext = useCallback(() => {
    isConfirming.on()
  }, [isConfirming])

  const handleClickGoBack = useCallback(() => {
    dispatch({ type: 'RESET_BOX_COUNT' })
    isConfirming.off()
  }, [dispatch, isConfirming])

  if (isAdvancedShipping) {
    return <AdvancedShipOptions {...props} />
  }

  const hasLabel = Boolean(state.orderData.label)
  const canEdit = !apiCreateShipment.isLoading && !hasLabel

  const carrierIcon = carrierIcons[state.carrierCode]

  return (
    <>
      {isConfirming.value ? (
        <>
          <div className="p-3">Confirm shipping carrier:</div>

          <img
            className="p-2"
            style={{ width: carrierIcon.width }}
            src={assetPath(carrierIcon.path)}
            alt={carrierIcon.title}
            title={carrierIcon.title}
          />

          <div className="p-3">Confirm box count:</div>

          <ul className="text-left">
            {Object.entries(state.boxes).map(([sku, count]) => {
              const size = sku.replace('BOX', '').toLowerCase()
              return (
                <li key={size}>
                  {size}: {count}
                </li>
              )
            })}
          </ul>
          <Button
            size="lg"
            color="danger"
            className="m-3"
            onClick={handleClickGoBack}
          >
            Go back to box count
          </Button>
          <Button
            size="lg"
            color="success"
            className="m-3"
            disabled={numBoxes <= 0 || apiCreateShipment.isLoading}
            onClick={handleClickComplete}
          >
            Confirm &amp; Print
          </Button>
        </>
      ) : (
        <>
          <div className="p-3">What boxes will be used?</div>

          <div className="d-flex flex-wrap m-3 align-items-center justify-content-center">
            {state.packageSizes.map((p) => {
              return (
                <BoxInput
                  disabled={!canEdit}
                  key={p.sku}
                  {...{ dispatch, state, ...p }}
                />
              )
            })}
          </div>

          <Button
            size="lg"
            color="success"
            className="m-3"
            disabled={numBoxes <= 0 || apiCreateShipment.isLoading}
            onClick={handleClickNext}
          >
            Next
          </Button>
        </>
      )}
    </>
  )
}

export default ShipOptions
