export const API_VERSION = 20250318

export function apiRequestParams<Params extends { [x: string]: any }>(
  params: { action?: string } & Params,
) {
  const p = { ...params }

  if (Object.hasOwn(params, 'action')) {
    p.action = `treeadmin_${params.action}`
  }

  return Object.keys(p)
    .map((key) => {
      const encKey = encodeURIComponent(key)
      const encValue = p[key] == null ? '' : encodeURIComponent(p[key])
      return `${encKey}=${encValue}`
    })
    .join('&')
}

export const encodeQueryString = (params: Record<string, string>) => {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    })
    .join('&')
}

export const CT_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8'
export const CT_JSON = 'application/json'

export async function apiFetch<ReturnType = any>(
  url: string,
  formData: BodyInit,
  contentType: string | false,
): Promise<ReturnType | Record<string, never>> {
  const ret = await fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers:
      contentType === false
        ? {}
        : {
            'content-type': contentType,
            version: String(API_VERSION),
          },
  })

  if (ret.status === 204) {
    return {}
  }

  const ct = ret.headers.get('content-type')
  if (ct && ct.indexOf('application/json') !== 0) {
    throw new Error('Received invalid response from server, please try again.')
  }

  const text = await ret.text()
  const json = JSON.parse(text)

  if (json?.error) {
    throw new Error(json.error)
  }

  if (ret.status === 500) {
    throw new Error('Internal Server Error')
  }

  if (ret.status === 404) {
    throw new Error('Resource not found')
  }

  if (ret.status >= 400 && ret.status <= 599) {
    throw new Error(`Unexpected response status ${ret.status}`)
  }

  return json
}

export function getApiBaseUrl() {
  return `${global.appConfig.wpBaseUrl}/wp-admin/admin-ajax.php`
}

export async function apiRequest<
  ReturnType = any,
  Params extends { action?: string; json?: any } = {
    action?: string
    json?: any
  },
>(params: string | Params): Promise<ReturnType | Record<string, never>> {
  const url = getApiBaseUrl()
  let nurl = url

  if (params && typeof params === 'object' && params.json && params.action) {
    nurl = `${url}?action=treeadmin_${encodeURIComponent(params.action)}`
    return await apiFetch<ReturnType>(
      nurl,
      JSON.stringify(params.json),
      CT_JSON,
    )
  }

  if (typeof params === 'object') {
    nurl = `${url}?action=treeadmin_${encodeURIComponent(params.action)}`
  } else if (typeof params === 'string' && params.indexOf('action=') === 0) {
    const p = params.split('&')[0].substring(7)
    nurl = `${url}?action=${p}`
  }

  const body =
    typeof params === 'string' ? params : apiRequestParams<Params>(params)
  return await apiFetch(nurl, body, CT_URLENCODED)
}

// For debugging.
global.debugApiRequest = apiRequest

export function openPrintWindow(params: Record<string, string>) {
  window.open(
    `${getApiBaseUrl()}/?${encodeQueryString(params)}`,
    'printwindow',
    'width=1000,height=800',
  )
}
