import { apiRequest } from 'api'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setCellLoading,
  setCellLoadingDone,
  setCellValue,
  setEditCell,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  useBoolean,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
} from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getStorage } from 'utils/storage'
import columnDef from './columnDef'
import type { Column } from 'components/Table/reducer/types'
import ActionButton from './Actions/ActionButton'
import StatusButton from './Actions/StatusButton'
import TableExportActionButton from 'components/TableExportActionButton'
import TableFilterRenderer from 'components/TableFilterRenderer'
import QuantityFilterButton from './QuantityFilterButton'

const Alert = withReactContent(Swal)

type ManageDropshipProductsProps = {
  readOnly: boolean
  pageTitle: string
  storageKey: string
}

const visibleFilters = [
  'q',
  'is_enabled',
  'pack',
  'ttc_product_variation_id',
  'inventory_amount',
]

const ManageDropshipProducts = (props: ManageDropshipProductsProps) => {
  const { pageTitle, storageKey } = props

  const filters = useFiltersWithUrlUpdater(
    'manageDropshipProductFilters',
    visibleFilters,
  )

  const query = filters.searchQueryString

  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  const [state, dispatch] = useTable({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const {
    isLoading,
    cellsLoading,
    editCell,
    didLoad,
    sortColumn,
    sortAsc,
    rows,
    selectedRows,
  } = state

  const columns = useTableColumns(storageKey, state, dispatch, columnDef)

  const requestParams = useMemo(
    () => ({ query: '', ...filters.requestProps }),
    [filters.requestProps],
  )

  const [triggerSearch] = useTableApi(
    'dropshipProducts_find',
    state,
    dispatch,
    requestParams,
    { autoReloadInterval: false },
  )

  /* Trigger search when filters or columns change. */
  useEffectIfObjectChanges(triggerSearch, requestParams)

  usePageTitle(pageTitle)

  const manageColumnsPanel = usePanelControl()

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  // Defer reloading the whole table (which is expensive and may change row order)
  // until user is done editing multiple cells in a row.
  const isEditing = editCell != null
  const didEdit = useBoolean(false)
  useEffect(() => {
    // Don't trigger until user edited something.
    if (!didEdit.value) {
      return
    }

    // Don't trigger until initial load.
    if (!didLoad) {
      return
    }

    // Don't trigger while editing.
    if (isEditing) {
      return
    }

    // Don't trigger while cells are loading.
    if (Object.keys(cellsLoading).length > 0) {
      return
    }

    triggerSearch(true)
  }, [triggerSearch, cellsLoading, isEditing, didLoad, didEdit])

  const handleChangeCell = useCallback(
    async ({
      value,
      oldValue,
      colId,
      rowId,
    }: {
      value: string
      oldValue: string
      colId: string
      rowId: string
      col: Column
    }) => {
      const row = rows.find((r) => r.id === rowId)
      if (!row) {
        return
      }

      try {
        didEdit.on()

        dispatch(setCellLoading(rowId, colId, value))

        await apiRequest({
          action: 'dropshipProducts_updateMapping',
          json: {
            key: colId,
            productId: rowId,
            value,
            oldValue,
            mdVariantId: row.md_variant_id,
          },
        })

        dispatch(setCellValue(rowId, colId, value))
        dispatch(setCellLoadingDone(rowId, colId))
      } catch (e) {
        dispatch(setCellLoadingDone(rowId, colId))

        await Alert.fire({ icon: 'error', title: <p>{e.message}</p> })

        triggerSearch(true)
      }
    },
    [dispatch, triggerSearch, didEdit, rows],
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      if (colId === 'ttc_product_variation_id') {
        dispatch(setEditCell(rowId, colId))
        return true
      }

      return false
    },
    [dispatch],
  )

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          onClickReload: triggerSearch,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        <StatusButton {...{ selectedRows, onModified: triggerSearch }} />
        <ActionButton {...{ selectedRows, onModified: triggerSearch }} />
        <TableExportActionButton
          {...{
            requestProps: filters.requestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
            action: 'dropshipProducts_export',
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <TableFilterRenderer filters={filters} columnDef={columnDef} />
        <QuantityFilterButton {...{ filters }} />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-inventory animated fadeIn">
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="variations"
                onClickCell={handleClickCell}
                onChangeCell={handleChangeCell}
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageDropshipProducts
