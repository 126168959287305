import NumericFilterButton from 'components/Filters/NumericFilterButton'
import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const AddFilterButton = ({ label, filter, filters }) => {
  const handleClick = useCallback(() => {
    const defaultValue = ['=', '0']

    filters.addFilter(filter, defaultValue)
  }, [filters, filter])

  return <DropdownItem onClick={handleClick}>{label}</DropdownItem>
}

const qtyFilters = [
  { label: 'TTC Price', filter: 'ttc_price' },
  { label: 'TTC Web', filter: 'numWeb' },
  { label: 'TTC Ground', filter: 'numGround' },
  { label: 'DS Base Price', filter: 'md_base_price' },
  { label: 'DS Retail Price', filter: 'md_retail_price' },
  { label: 'DS Price %', filter: 'price_ratio_base_retail' },
  { label: 'TTC Price %', filter: 'price_ratio_base_ttc' },
  { label: 'Zone Inventory', filter: 'zone_qty' },
  { label: 'DS Inventory', filter: 'inventory_amount' },
  { label: 'Pack Size', filter: 'pack_size' },
]

type QuantityFilterButtonProps = {
  filters: FiltersType
}

const QuantityFilterButton = (props: QuantityFilterButtonProps) => {
  const { filters } = props

  const toggler = useDropDownToggler()

  return (
    <>
      {qtyFilters
        .filter((qtyFilter) => filters.has(qtyFilter.filter))
        .map((qtyFilter) => {
          return (
            <NumericFilterButton
              key={qtyFilter.filter}
              {...{
                filter: filters[qtyFilter.filter],
                label: qtyFilter.label,
              }}
            />
          )
        })}
      <Dropdown nav {...toggler}>
        <DropdownToggle nav caret>
          <span>Price&amp;Qty</span>
        </DropdownToggle>
        <DropdownMenu>
          {qtyFilters.map((qtyFilter) => {
            return (
              <AddFilterButton
                {...{ filters }}
                key={qtyFilter.filter}
                label={qtyFilter.label}
                filter={qtyFilter.filter}
              />
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default QuantityFilterButton
