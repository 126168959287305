import type { OrderLineItem } from '@ttc/api/orders'
import type {
  StoreCreditReason,
  StoreCreditTemplate,
  StoreCreditsCreateRequest,
  StoreCreditsCreateResponse,
} from '@ttc/api/storeCredits'
import AuthContext from 'AuthContext'
import type { FormDef, FormDefElement } from 'app/components/Form/types'
import { MANAGESTORECREDITTEMPLATES_READWRITE } from 'caps'
import { EditPanel, EditPanelCard, FormRenderer, TextField } from 'components'
import SelectTemplate from 'containers/ManageStoreCredits/SelectTemplate'
import { useApi, useFormWithDef } from 'hooks'
import {
  type ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Col, Row } from 'reactstrap'
import { formatPrice, numberWithCommas } from 'utils/price'
import LineItemEditor from './LineItemEditor'
import { useQtyEditor } from './useQtyEditor'
import WarrantyInfo from './WarrantyInfo'
import { useWarrantyInfo } from './useWarrantyInfo'
import type { SelectOption } from '@ttc/api/types'

const formDefTemplate: FormDef = [
  {
    label: 'Customer Email',
    name: 'email',
    type: 'text',
    defaultValue: null,
  },
  {
    label: 'Internal Reason (not shown to customer)',
    name: 'reason',
    type: 'select',
    options: [],
    defaultValue: 'UNSPECIFIED',
  },
  {
    label: 'Internal Reason Note (not shown to customer)',
    placeholder: 'Enter a note about the reason',
    name: 'reason_note',
    type: 'textarea',
    defaultValue: '',
  },
  {
    label: '',
    placeholder: '',
    name: 'amount',
    type: 'custom',
  },
]

const perItemShippingOptions = [
  {
    value: '0',
    label: 'Free ($0)',
  },
  {
    value: '10',
    label: '$10',
  },
  {
    value: '15',
    label: '$15',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
]
const amountFormDef: FormDef = [
  {
    label: 'Use Normal Shipping',
    name: 'use_normal_shipping',
    type: 'checkbox',
    defaultValue: false,
  },
  {
    label: 'Per Item Shipping Amount',
    name: 'shipping_amount',
    type: 'select',
    options: perItemShippingOptions,
    defaultValue: '0',
  },
  {
    label: 'Custom Shipping Amount',
    name: 'custom_shipping_amount',
    type: 'custom',
    defaultValue: '',
  },
]

type FormState = {
  email: string
  reason: string
  reason_note: string
  amount: string
  shipping_amount: string
  custom_shipping_amount: string
  use_normal_shipping: boolean
  template: StoreCreditTemplate
  text: string
}

/**
 * IssueStoreCreditPanel is a component for issuing store credits to customers.
 * It allows the selection of a store credit template, customization of the credit amount,
 * and selection of items affected by the credit.
 *
 * @param isOpen - Indicates if the panel is open.
 * @param onClose - Callback to close the panel.
 * @param orderId - The ID of the order for which the credit is being issued.
 * @param customerEmail - The email of the customer receiving the credit.
 * @param firstName - The first name of the customer.
 * @param items - The items in the order.
 */
const IssueStoreCreditPanel = ({
  isOpen,
  onClose,
  orderId,
  customerEmail,
  firstName,
  items,
}: {
  isOpen: boolean
  onClose: () => void
  orderId: number
  customerEmail: string
  firstName: string
  items: OrderLineItem[]
}) => {
  const { hasCap } = useContext(AuthContext)

  const getReasons = useApi<{ reasons: SelectOption[] }>(
    () => ({ action: 'storeCredits_getReasons' }),
    null,
    { autoPerform: true },
  )
  const reasonOptions = useMemo(
    () => getReasons.result?.reasons || [],
    [getReasons.result],
  )

  const formDef = useMemo(() => {
    let formDef = [...formDefTemplate]

    formDef = formDef.map((def: FormDefElement) => {
      if (def.name === 'reason') {
        return {
          ...def,
          options: [
            {
              label: '',
              value: '',
            },
            ...reasonOptions,
          ],
        }
      }

      if (def.name === 'email') {
        return {
          ...def,
          defaultValue: customerEmail,
        }
      }

      return def
    })

    return formDef
  }, [reasonOptions, customerEmail])

  const form = useFormWithDef<FormState>([...formDef, ...amountFormDef])

  const { template, text } = form.state
  const [storeCreditPercentage, setStoreCreditPercentage] = useState(
    template?.defaultAmountPercentage || 0,
  )

  useEffect(() => {
    if (template) {
      setStoreCreditPercentage(template.defaultAmountPercentage)
    }
  }, [template])

  const apiSubmit = useApi<
    StoreCreditsCreateResponse,
    StoreCreditsCreateRequest
  >({ action: 'storeCredits_create' }, null, { errorModal: true })

  const handleChangeTemplate = useCallback(
    (_name: string, template: StoreCreditTemplate) => {
      const text =
        template != null
          ? template.mail.replace('{{firstName}}', firstName)
          : ''

      const reasonValue = template?.defaultReason ? template.defaultReason : ''
      const reason = reasonOptions.find((o) => o.value === reasonValue) || null

      const perItemShippingAmount = template?.defaultPerItemShipping
        ? template.defaultPerItemShipping
        : 0

      // if perItemShippingAmount is equal to one of the obj.value in perItemShippingOptions,
      // then set shippingAmount to that. otherwise set it to 'custom'
      const isCustom = !perItemShippingOptions.find(
        (obj) => obj.value === perItemShippingAmount.toString(),
      )
      const shippingAmount = isCustom
        ? 'custom'
        : perItemShippingAmount.toString()
      const customShippingAmount = isCustom
        ? perItemShippingAmount.toString()
        : ''

      form.setState({
        ...form.state,
        template,
        text,
        reason: reason ? reason.value : null,
        shipping_amount: shippingAmount,
        custom_shipping_amount: customShippingAmount,
        use_normal_shipping: template?.useNormalShipping || false,
      })
    },
    [firstName, reasonOptions, form],
  )

  const useNormalShipping = form.state.use_normal_shipping
  useEffect(() => {
    if (useNormalShipping && Number(form.state.shipping_amount) !== 0) {
      form.set('shipping_amount', 0)
    }
  }, [form, useNormalShipping])

  const warrantyInfo = useWarrantyInfo(orderId)

  const qtyEditor = useQtyEditor(items)

  const handleSubmit = useCallback(async () => {
    const formSubmit = async () => {
      const {
        email,
        reason,
        reason_note,
        amount,
        shipping_amount,
        custom_shipping_amount,
        text,
        use_normal_shipping,
      } = form.state

      const items = Object.keys(qtyEditor.state).map((productId) => {
        return {
          product_id: Number(productId),
          quantity: Number(qtyEditor.state[productId]),
        }
      })

      const requestJson: StoreCreditsCreateRequest = {
        orderId,
        reasonEnum: reason as StoreCreditReason,
        reason: reason_note,
        email,
        mail: text,
        amount: Number(amount),
        perItemShipping:
          shipping_amount === 'custom'
            ? Number(custom_shipping_amount)
            : Number(shipping_amount),
        useNormalShipping: use_normal_shipping,
        items,
        createdVia: 'STORE_CREDIT_PANEL',
        creditPercentage: template?.defaultAmountPercentage,
      }

      const ret = await apiSubmit.performRequest({ json: requestJson })

      if (ret) {
        onClose()
      }
    }

    await formSubmit()
  }, [apiSubmit, form.state, onClose, orderId, qtyEditor.state, template])

  const isLoading = apiSubmit.isLoading

  const canSubmit =
    template != null &&
    text != null &&
    text.length > 0 &&
    form.state.email != null &&
    form.state.email.length > 0 &&
    form.state.reason != null &&
    form.state.reason !== '' &&
    Number(form.state.amount) > 0 &&
    !isLoading

  const selectedAmount = useMemo(() => {
    return items.reduce((total, item) => {
      const qty = qtyEditor.getProductQty(item.product_id)

      return total + item.price * qty
    }, 0)
  }, [items, qtyEditor])

  const calculatedAmount = selectedAmount * (storeCreditPercentage / 100)

  const handleClickApply = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      const amount = numberWithCommas(
        String((calculatedAmount / 100).toFixed(2)),
      ).replace(/,/g, '')
      form.set('amount', amount)
    },
    [form, calculatedAmount],
  )

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption="Issue Store Credit"
      submitButtonLabel={'Issue Store Credit'}
      {...{
        isOpen,
        onClose,
        isLoading,
        canSubmit,
      }}
    >
      <EditPanelCard caption="Details" stateId="issueStoreCredit.details">
        <SelectTemplate
          autoSelectFirst={false}
          className="mb-2"
          name="template"
          value={template ? template.value : null}
          onChange={handleChangeTemplate}
        />
        {hasCap(MANAGESTORECREDITTEMPLATES_READWRITE) && template == null ? (
          <a href="/store-credit/config" target="_blank" rel="noreferrer">
            Manage Templates...
          </a>
        ) : null}
        {template != null ? (
          <>
            <textarea
              className="mb-2"
              name="text"
              style={{
                width: '100%',
                height: 200,
                padding: 10,
                borderRadius: 5,
                border: '1px solid #ccc',
              }}
              value={text}
              {...form.inputProps}
            />
            <FormRenderer
              {...{
                form,
                formDef,
              }}
            />
          </>
        ) : null}
      </EditPanelCard>
      {template != null ? (
        <>
          <EditPanelCard
            caption="Warranty Info"
            stateId="issueStoreCredit.warranty"
          >
            <WarrantyInfo {...{ warrantyInfo }} />
          </EditPanelCard>
          <EditPanelCard
            caption={`Items (${qtyEditor.total} selected)`}
            stateId="issueStoreCredit.items"
            bodyProps={{ className: 'p-0' }}
          >
            <div className="ml-3 mt-1 mb-1">Select affected items</div>
            <LineItemEditor
              {...{
                items,
                qtyEditor,
              }}
            />
            <div className="ml-3 mt-1 mb-1">
              <Row>
                <Col xs={4}>Selected:</Col>
                <Col>
                  {qtyEditor.total} items = ${formatPrice(selectedAmount)}
                </Col>
              </Row>
              <Row className="mr-3">
                <Col xs={4}>Credit %:</Col>
                <Col>
                  <TextField
                    isNumeric={true}
                    id="store-credit-percentage"
                    label=""
                    name="storeCreditPercentage"
                    value={storeCreditPercentage}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      setStoreCreditPercentage(Number(e.target.value))
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Calculated:</Col>
                <Col>
                  ${formatPrice(calculatedAmount)}{' '}
                  <a href="" onClick={handleClickApply}>
                    Apply
                  </a>
                </Col>
              </Row>
            </div>
          </EditPanelCard>
          <EditPanelCard
            caption="Shipping Amount"
            stateId="issueStoreCredit.shippingAmount"
          >
            <FormRenderer
              {...{
                form,
                formDef: [amountFormDef[0]],
              }}
            />
            {form.state.use_normal_shipping ? null : (
              <FormRenderer
                {...{
                  form,
                  formDef: [amountFormDef[1]],
                }}
              />
            )}
            {form.state.shipping_amount === 'custom' ? (
              <div className="floating-labels">
                <Row>
                  <Col>
                    <TextField
                      className="mt-2"
                      name="custom_shipping_amount"
                      value={form.state.custom_shipping_amount}
                      id="input-custom-shipping-amount"
                      required
                      label="Custom Per Plant Shipping Amount"
                      {...form.inputProps}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
          </EditPanelCard>
          <EditPanelCard
            caption="Credit Amount"
            stateId="issueStoreCredit.amount"
          >
            <div className="floating-labels">
              <Row>
                <Col>
                  <TextField
                    name="amount"
                    value={form.state.amount}
                    id="input-credit-amount"
                    required
                    label="Dollar Amount"
                    {...form.inputProps}
                  />
                </Col>
              </Row>
            </div>
          </EditPanelCard>
        </>
      ) : null}
    </EditPanel>
  )
}

export default IssueStoreCreditPanel
